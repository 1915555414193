import React from "react"

/* IMPORT PLUGINS */
/* IMPORT COMPONENTS */

/* IMPORT STYLE */
import "./CardAdvice.less"

/* IMPORT CONTEXT LNG */
/* IMPORT HELPERS */
/* IMPORT ICONS */
import { BiRadioCircle } from "react-icons/bi"

const CardAdvice = ({ icon, title, list, bg, color }) => {
  return (
    <div
      className={`${
        bg === "blue"
          ? "wrapper-card-advice wrapper-card-advice-blue"
          : "wrapper-card-advice wrapper-card-advice-white"
      }`}
    >
      <div className="img-card-advice">
        <div className="custom-icon-card-advice">{icon}</div>
      </div>
      <div className="title-card-advice" style={{ color: `${color}`}}>{title}</div>
      <ul className="list-card-advice" style={{ color: `${color}`}}>
        {list.map((el, i) => (
          <span className="list-item-card-advice">
            <BiRadioCircle />
            <li key={i}>{el}</li>
          </span>
        ))}
      </ul>
    </div>
  )
}

export default CardAdvice
