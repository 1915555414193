import React, { useLayoutEffect, useState } from "react"
import isBrowser from "../helpers/isBrowser"

export default function useScroll(
  isHeaderRef,
  isAboutRef,
  isAdviceRef,
  isEcosystemRef,
  isBlogRef,
  isContactRef,
) {
  /* check is wwindow */
  const checkIsBrowser = isBrowser()

  /* START STATE SHOW */
  const [show, setShow] = useState({
    isHeader: true,
    isAbout: false,
    isAdvice: false,
    isEcosystem: false,
    isBlog: false,
    isContact: false,
  })

  /* START COMPONENT WILLMOUNT */
  useLayoutEffect(() => {
    const topPos = el => el?.getBoundingClientRect().top
    //added to reduce redundancy
    const headerPos = isHeaderRef && topPos(isHeaderRef?.current),
      aboutPos = topPos(isAboutRef.current),
      advicePos = topPos(isAdviceRef.current),
      ecoSystemPos = topPos(isEcosystemRef.current),
      blogPos = topPos(isBlogRef.current),
      contactPos = topPos(isContactRef.current)

    const onScroll = () => {
      const scrollPos = checkIsBrowser && window.scrollY 

      if (headerPos < scrollPos) {
        setShow(state => ({
          ...state,
          isHeader: true,
          isAbout: false,
          isAdvice: false,
          isEcosystem: false,
          isBlog: false,
          isContact: false,
        }))
      }

      if (aboutPos < scrollPos + 60) {
        setShow(state => ({
          ...state,
          isHeader: false,
          isAbout: true,
          isAdvice: false,
          isEcosystem: false,
          isBlog: false,
          isContact: false,
        }))
      }

      if (advicePos < scrollPos + 85) {
        setShow(state => ({
          ...state,
          isHeader: false,
          isAbout: false,
          isAdvice: true,
          isEcosystem: false,
          isBlog: false,
          isContact: false,
        }))
      }

      if (ecoSystemPos < scrollPos + 1350) {
        setShow(state => ({
          ...state,
          isHeader: false,
          isAbout: false,
          isAdvice: false,
          isEcosystem: true,
          isBlog: false,
          isContact: false,
        }))
      }

      if (blogPos < scrollPos + 1480) {
        setShow(state => ({
          ...state,
          isHeader: false,
          isAbout: false,
          isAdvice: false,
          isEcosystem: false,
          isBlog: true,
          isContact: false,
        }))
      }

      if (contactPos < scrollPos + 1490) {
        setShow(state => ({
          ...state,
          isHeader: false,
          isAbout: false,
          isAdvice: false,
          isEcosystem: false,
          isBlog: false,
          isContact: true,
        }))
      }
    }

    checkIsBrowser && window.addEventListener("scroll", onScroll)
    return () =>
      checkIsBrowser && window.removeEventListener("scroll", onScroll)
  }, [])
  return { show }
}
