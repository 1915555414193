import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import { lngContext } from "../components/lngContext"

export default function AdviceData() {
  const { value, setValue } = useContext(lngContext)

  useEffect(() => {
    if (!value) {
      return;
    }

    setstate({
      objManagement: {
        icon: (
          <StaticImage
            src="../images/gestion.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="image management buildeeng"
          />
        ),
        title:
          value === "fr" ? "Facilities Management" : "Facilities management",
        list: [
          "Audit",
          value === "fr" ? "Conseil" : "Consulting",
          value === "fr"
            ? "Expression des besoins / Cahier des Charges"
            : "Due Diligence",
          value === "fr" ? "Management de Transition" : "Workplace Strategy",
          value === "fr"
            ? "Mobilisation /Transformation"
            : "Mobilization / Transformation",
          value === "fr" ? "Conduite du Changement" : "Outsourcing",
        ],
      },
      objImmobilier: {
        icon: (
          <StaticImage
            src="../images/immobilier.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="image immobilier buildeeng"
          />
        ),
        title: value === "fr" ? "Immobilier" : "Corporate Real Estate",
        list: [
          value === "fr" ? "Stratégie" : "Strategy",
          value === "fr" ? "Recherche" : "Business Case",
          value === "fr" ? "Due Diligence" : "Due Diligence",
          value === "fr" ? "Conseil" : "Negociation",
          value === "fr" ? "Analyse Financière" : "Portfolio Consolidation",
          value === "fr" ? "Négociation" : "Negociation",
          value === "fr"
            ? "Consolidation de portfolio"
            : "Market Research",
        ],
      },
      objGestion: {
        icon: (
          <StaticImage
            src="../images/management.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="image gestion buildeeng"
          />
        ),
        title:
          value === "fr"
            ? "Gestion et coordination de projet"
            : "Project Management & Coordination",
        list: [
          value === "fr" ? "Aménagement" : "Fit-Out",
          value === "fr" ? "Déménagement" : "Project  Management",
          value === "fr" ? "Externalisation" : "Project Controls",
          value === "fr" ? "La gestion des coûts" : "Cost Management",
          value === "fr" ? "Administration des contrats" : "Contract administration",
          value === "fr" ? "Santé et sécurité" : "Health & Safety",

        ],

   
      },
      objCapital: {
        icon: (
          <StaticImage
            src="../images/capital.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="image capital buildenng"
          />
        ),
        title: value === "fr" ? "Capital humain" : "Human capital",
        list: [
          value === "fr"
            ? "Accompagnement à la professionnalisation"
            : "Coaching",
          value === "fr"
            ? "Formation et développement des compétences"
            : "Tailored and individual training ",
        ],
      },
    })
  }, [value])

  const [state, setstate] = useState({
    objManagement: {
      icon: (
        <StaticImage
          src="../images/gestion.png"
          width={50}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image management buildeeng"
        />
      ),
      title: value === "fr" ? "Facilities Management" : "Facilities management",
      list: [
        "AMO",
        "Audit",
        value === "fr" ? "Conseil" : "Advice",
        value === "fr"
          ? "Expression des besoins / Cahier des Charges"
          : "Expression of needs / Specifications",
        value === "fr" ? "Management de Transition" : "Interim management",
        value === "fr"
          ? "Mobilisation /Transformation"
          : "Mobilization / Transformation",
        value === "fr" ? "Conduite du Changement" : "Change Management",
      ],
    },
    objImmobilier: {
      icon: (
        <StaticImage
          src="../images/immobilier.png"
          width={50}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image immobilier buildeeng"
        />
      ),
      title: value === "fr" ? "Immobilier" : "Immovable",
      list: [
        value === "fr" ? "Stratégie" : "Strategy",
        value === "fr" ? "Recherche" : "Research",
        value === "fr" ? "Due Diligence" : "Due Diligence",
        value === "fr" ? "Conseil" : "Market Research",
        value === "fr" ? "Analyse Financière" : "Portfolio Consolidation",
        value === "fr" ? "Négociation" : "Negociation",
        value === "fr"
          ? "Consolidation de portfolio"
          : "Planning",
      ],
    },
    objGestion: {
      icon: (
        <StaticImage
          src="../images/management.png"
          width={50}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image gestion buildeeng"
        />
      ),
      title:
        value === "fr"
          ? "Gestion et coordination de projet"
          : "Project management and coordination",
      list: [
        value === "fr" ? "Aménagement" : "Arrangement",
        value === "fr" ? "Déménagement" : "Move",
        value === "fr" ? "Externalisation" : "Outsourcing",
      ],
    },
    objCapital: {
      icon: (
        <StaticImage
          src="../images/capital.png"
          width={50}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image capital buildenng"
        />
      ),
      title: value === "fr" ? "Capital humain" : "Human capital",
      list: [
        value === "fr"
          ? "Accompagnement à la professionnalisation"
          : "Support for professionalization",
        value === "fr"
          ? "Formation et développement des compétences"
          : "Training and skills development",
      ],
    },
  })

  return { state }
}
