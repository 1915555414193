import React, { useContext, useState } from "react"
import { lngContext } from "../components/lngContext"
import { nanoid } from "nanoid"
import { StaticImage } from "gatsby-plugin-image"

const CardBlogData = () => {
  const { value, setValue } = useContext(lngContext)
  const [cardBlogData, setCardBlogData] = useState([
    {
      id: nanoid(),
      img: (
        <StaticImage
          src="../../images/blog/img-blog-externalisation.png"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image blog externalisation"
        />
      ),
      title:
        value === "fr"
          ? "Externalisation, les facteurs de réussite"
          : "Outsourcing, the success factors",
      titleModal: "Externalisation",
    },
    {
      id: nanoid(),
      title: "New threats, how to be prepared?",
      img: (
        <StaticImage
          src="../../images/blog/img-blog-threats.jpeg"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image blog threats en"
        />
      ),
      titleModal: "threatsEn",
    },
    {
      id: nanoid(),
      title: "Nouvelles menaces, comment s'y préparer?",
      img: (
        <StaticImage
          src="../../images/blog/img-blog-threats.jpeg"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image blog threats fr"
        />
      ),
      titleModal: "threatsFr",
    },
  ])
  return { cardBlogData }
}

export default CardBlogData
