import React, { useContext, useState } from "react"

/* IMPORT PLUGINS */
import { StaticImage } from "gatsby-plugin-image"

/* IMPORT COMPONENTS */
/* IMPORT STYLE */

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */

/* IMPORT ICONS */
import { BsArrowRight } from "react-icons/bs"
import { IoIosArrowForward } from "react-icons/io"
import { IoIosArrowBack } from "react-icons/io"

let count = 0
const Experts = () => {
  const { value, setValue } = useContext(lngContext)

  const [currentImage, setCurrentImage] = useState({
    img: (
      <StaticImage
        src="../../images/img-ecosystem.png"
        width={660}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="image ecosystem buildeeng"
      />
    ),
    nameExpert: `BADREDDINE`,
    titleExpert:
      value === "fr"
        ? `Expert en Facilities & Workplace Management`
        : `Expert in Facilities & Workplace Management`,
    descriptionExpert:
      value === "fr"
        ? `Expert en Facilities et Workplace Management, avec une large
    expérience dans l'Immobilier, l'externalisation, le pilotage de
    projets, la gestion du changement et le conseil auprès de grands
    groupes et dans des environnements internationaux. Expertise dans les
    différents métiers autour du l’environnement de travail, comme
    l'exploitation, la maintenance, les services aux occupants, HSE, la
    technologie, l'outsourcing, etc... Une large expérience dans
    l’implémentation et la gestion de contrats globaux en FM, la conduite
    du changement, la mise en place de stratégies, l'optimisation des
    opérations, des coûts et des services; le pilotage de projets
    d’aménagements d’espace de travail et de déménagements. A un excellent
    relationnel, une agilité et une capacité à travailler en équipe et
    dans des conditions de stress. Et une très bonne communication.`
        : `Expert in Facilities and Workplace Management, with a broad
    experience in Real Estate, outsourcing, management of
    projects, change management and advice to large
    groups and in international environments. Expertise in
    different professions around the work environment, such as
    operation, maintenance, occupant services, HSE,
    technology, outsourcing, etc ... Extensive experience in
    the implementation and management of global FM contracts, the conduct
    change, the implementation of strategies, the optimization of
    operations, costs and services; project management
    workspace arrangements and relocations. Has an excellent
    interpersonal skills, agility and the ability to work in a team and
    under stressful conditions. And very good communication.`,
  })

  const expertsImgs = [
    {
      img: (
        <StaticImage
          src="../../images/img-ecosystem.png"
          width={660}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image ecosystem buildeeng"
        />
      ),
      nameExpert: `BADREDDINE`,
      titleExpert:
        value === "fr"
          ? `Expert en Facilities & Workplace Management`
          : `Expert in Facilities & Workplace Management`,
      descriptionExpert:
        value === "fr"
          ? `Expert en Facilities et Workplace Management, avec une large
      expérience dans l'Immobilier, l'externalisation, le pilotage de
      projets, la gestion du changement et le conseil auprès de grands
      groupes et dans des environnements internationaux. Expertise dans les
      différents métiers autour du l’environnement de travail, comme
      l'exploitation, la maintenance, les services aux occupants, HSE, la
      technologie, l'outsourcing, etc... Une large expérience dans
      l’implémentation et la gestion de contrats globaux en FM, la conduite
      du changement, la mise en place de stratégies, l'optimisation des
      opérations, des coûts et des services; le pilotage de projets
      d’aménagements d’espace de travail et de déménagements. A un excellent
      relationnel, une agilité et une capacité à travailler en équipe et
      dans des conditions de stress. Et une très bonne communication.`
          : `Expert in Facilities and Workplace Management, with a broad
      experience in Real Estate, outsourcing, management of
      projects, change management and advice to large
      groups and in international environments. Expertise in
      different professions around the work environment, such as
      operation, maintenance, occupant services, HSE,
      technology, outsourcing, etc ... Extensive experience in
      the implementation and management of global FM contracts, the conduct
      change, the implementation of strategies, the optimization of
      operations, costs and services; project management
      workspace arrangements and relocations. Has an excellent
      interpersonal skills, agility and the ability to work in a team and
      under stressful conditions. And very good communication.`,
    },
    {
      img: (
        <StaticImage
          src="../../images/img-ecosystem.png"
          width={660}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="image ecosystem buildeeng"
        />
      ),
      nameExpert: `Sana`,
      titleExpert: `PMO`,
      descriptionExpert:
        value === "fr"
          ? `Sana une grande expérience dans le retail, la gestion des baux, les traveaux d'amenagements et la gestion de la relation avec les propriétaires.
      Une expertise de la gestion des services aux occupants, la back office et la satisfaction client. Orientée client, avec d'excellente qualités relationnelles et de communication.`
          : `Sana has extensive experience in retail, lease management, development work and management of the relationship with owners.
      Expertise in the management of occupant services, back office and customer satisfaction. Client oriented, with excellent interpersonal and communication skills`,
    },
  ]

  const getCurrentImage = countSlider => {
    const getNextImage =
      countSlider <= expertsImgs.length
        ? expertsImgs.find((el, i) => i === countSlider)
        : expertsImgs.find((el, i) => i === 0)

    setCurrentImage(getNextImage)
  }

  const nextSlider = () => {
    if (count >= expertsImgs.length - 1) {
      count = 0
    } else {
      count += 1
    }
    getCurrentImage(count)
  }

  const prevSlider = () => {
    if (count === 0) {
      count = expertsImgs.length - 1
    } else {
      count -= 1
    }
    getCurrentImage(count)
  }

  return (
    <>
      <div className="bloc-left-ecosystem">
        <p className="wrapper-title-experts">
          <span className="title-experts">
            {value === "fr" ? "NOS EXPERTS" : "OUR EXPERTS"}
          </span>
          <span className="icon-title-experts">
            <BsArrowRight />
          </span>
        </p>
        <p className="name-expert">{currentImage.nameExpert}</p>
      </div>
      <div className="bloc-img-ecosystem">{currentImage.img}</div>
      <div className="description-expert-ecosystem">
        <p className="title-description-expert-ecosystem">
          {currentImage.titleExpert}
        </p>
        <p className="description-expert">{currentImage.descriptionExpert}</p>
      </div>
      <div className="scrolling-ecosystem">
        <div className="arrow-right" onClick={nextSlider}>
          <IoIosArrowForward />
        </div>
        <div className="arrow-left" onClick={prevSlider}>
          <IoIosArrowBack />
        </div>
      </div>
    </>
  )
}

export default Experts
