import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import "./ModalExample.less"

const ModalExample = ({ show, toggle, title, children }) => {
  return (
    <Modal isOpen={show} toggle={toggle} size="lg">
      <div className="content">
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </div>
    </Modal>
  )
}

export default ModalExample
