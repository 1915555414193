import React, { useContext } from "react"

/* IMPORT PLUGINS */
/* IMPORT COMPONENTS */
import Experts from "./Experts"
import Partners from "./Partners"

/* IMPORT STYLE */
import "./EcoSystem.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */
/* IMPORT ICONS */

const EcoSystem = () => {
  const { value, setValue } = useContext(lngContext)

  return (
    <div className="wrapper-ecosystem py-5" id="ecosystem">
      <div className="container">
        <div className="title-ecosystem">
          <p>{value === "fr" ? "Equipe" : "Team"}</p>
          <div className="bar-title-ecosystem"></div>
        </div>

        <div className="content-ecosystem-expert mt-4">
          <Experts />
        </div>
        <div className="content-ecosystem-partner">
        
        </div>
      </div>
    </div>
  )
}

export default EcoSystem
