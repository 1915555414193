import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { contentModalExternalisation } from "../../constants/modalExternalisation"
import { contentModalThreatsEn } from "../../constants/modalThreatsEn"
import { contentModalThreatsFr } from "../../constants/modalThreatsFr"
import ModalExample from "../Modal"

/* IMPORT PLUGINS */
/* IMPORT COMPONENTS */

/* IMPORT STYLE */
import "./CardBlog.less"

/* IMPORT CONTEXT LNG */
/* IMPORT HELPERS */

/* IMPORT ICONS */

const CardBlog = ({ title, img, titleModal }) => {
  const { stateExternalisation } = contentModalExternalisation()
  const { stateThreatsEn } = contentModalThreatsEn()
  const { stateThreatsFr } = contentModalThreatsFr()

  const [showModalExternalisation, setShowModalExternalisation] = useState(
    false
  )
  const [showModalThreatsEn, setShowModalThreatsEn] = useState(false)
  const [showModalThreatsFr, setShowModalThreatsFr] = useState(false)

  const handleShowModalExternalisation = () => {
    setShowModalExternalisation(true)
  }
  const handleHidedModalExternalisation = () => {
    setShowModalExternalisation(false)
  }

  const handleShowModalThreatsEn = () => {
    setShowModalThreatsEn(true)
  }
  const handleHideModalThreatsEn = () => {
    setShowModalThreatsEn(false)
  }

  const handleShowModalThreatsFr = () => {
    setShowModalThreatsFr(true)
  }
  const handleHideModalThreatsFr = () => {
    setShowModalThreatsFr(false)
  }

  const handleShowModal = () => {
    switch (titleModal) {
      case "Externalisation":
        handleShowModalExternalisation()
        break
      case "threatsEn":
        handleShowModalThreatsEn()
        break
      case "threatsFr":
        handleShowModalThreatsFr()
        break
      default:
        console.log("default")
    }
  }

  const imgExternalisation = (
    <StaticImage
      src="../../images/blog/img-blog-externalisation.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="image blog externalisation"
    />
  )
  const imgThreatsEn = (
    <StaticImage
      src="../../images/blog/img-blog-threats.jpeg"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="image blog threats"
    />
  )
  const imgThreatsFr = (
    <StaticImage
      src="../../images/blog/img-blog-threats.jpeg"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="image blog threats"
    />
  )

  return (
    <div className="wrapper-card-blog" onClick={handleShowModal}>
      <div className="main-card-blog">
        <div className="img-bg-card">
          {titleModal === "Externalisation" && imgExternalisation}
          {titleModal === "threatsEn" && imgThreatsEn}
          {titleModal === "threatsFr" && imgThreatsFr}
        </div>
        <div className="date"></div>
        <div className="content-card-blog">
          <div className="bar-card-blog"></div>
          <p className="title-card-blog">{title}</p>
        </div>
      </div>
      <ModalExample
        show={showModalExternalisation}
        toggle={handleHidedModalExternalisation}
        title="Externalisation, les facteurs de réussite"
      >
        <div>{stateExternalisation.text}</div>
      </ModalExample>
      <ModalExample
        show={showModalThreatsEn}
        toggle={handleHideModalThreatsEn}
        title="New threats, how to be prepared?"
      >
        <div>{stateThreatsEn.text}</div>
      </ModalExample>
      <ModalExample
        show={showModalThreatsFr}
        toggle={handleHideModalThreatsFr}
        title="Nouvelles menaces, comment s'y préparer?"
      >
        <div>{stateThreatsFr.text}</div>
      </ModalExample>
    </div>
  )
}

export default CardBlog
