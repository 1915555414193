import React, { useContext, useEffect, useState } from "react"

/* IMPORT PLUGINS */
import { StaticImage } from "gatsby-plugin-image"

/* IMPORT COMPONENTS */
import NavBar from "../NavBar"

/* IMPORT STYLE */
import "./Header.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */

/* IMPORT ICONS */
import "../../utils/font-awesome"
import { AiOutlineLinkedin } from "react-icons/ai"
import { IoIosArrowForward } from "react-icons/io"
import { IoIosArrowBack } from "react-icons/io"

let count = 0
const speed = 7000

const Header = ({ show }) => {
  const { value, setValue } = useContext(lngContext)

  const [currentImage, setCurrentImage] = useState(
    <div className="imgx fade-in">
      <StaticImage
        src={`../../images/slider/slider-1.png`}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="image slider 1 buildeeng"
      />
    </div>
  )

  const sliderImgs = [
    <div className="imgx fade-in">
      <StaticImage
        src={`../../images/slider/slider-1.png`}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="image slider 1 buildeeng"
      />
    </div>,
    <div className="imgx fade-in">
      <StaticImage
        src={`../../images/slider/slider-2.png`}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="image slider 2 buildeeng"
      />
    </div>,
    <div className="imgx fade-in">
      <StaticImage
        src={`../../images/slider/slider-3.png`}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="image slider 3 buildeeng"
      />
    </div>,
  ]

  const getCurrentImage = countSlider => {
    const getNextImage =
      countSlider <= sliderImgs.length
        ? sliderImgs.find((el, i) => i === countSlider)
        : sliderImgs.find((el, i) => i === 0)

    setCurrentImage(getNextImage)
  }

  const nextSlider = () => {
    pauseScrollingSlider()
    getCurrentImage(count)
    if (count >= sliderImgs.length - 1) {
      count = 0
    } else {
      count += 1
    }
  }

  const prevSlider = () => {
    pauseScrollingSlider()
    if (count === 0) {
      count = sliderImgs.length - 1
    } else {
      count -= 1
    }
    getCurrentImage(count)
  }

  const [puseScrolling, setPuseScrolling] = useState(false)

  const pauseScrollingSlider = () => {
    setPuseScrolling(true)
    setTimeout(() => setPuseScrolling(false), speed)
  }

  useEffect(() => {
    if (puseScrolling) {
      count = count
      return
    }
    if (!puseScrolling) {
      if (count >= sliderImgs.length - 1) {
        count = 0
      } else {
        count += 1
      }
      setTimeout(() => getCurrentImage(count), speed)
    }
  }, [currentImage, puseScrolling])

  const [lngSelected, setLngSelected] = useState("FRANÇAIS")
  const [showListLng, setShowListLng] = useState(false)

  const handleShowList = () => {
    setShowListLng(state => !state)
  }

  const handleChangeLng = val => {
    setLngSelected(val)
    setShowListLng(false)
    if (val === "FRANÇAIS") setValue("fr")
    if (val === "ENGLISH") setValue("en")
  }

  return (
    <div className="wrapper-header" id="home">
      <div className="bar-header">
        <div className="container">
          <a
            href="https://www.linkedin.com/company/buildeeng/"
            target="_blank"
            className="linkedin-header"
          >
            <p className="wrapper-linkedin-header">
              <span className="icon-linkedin-header">
                <AiOutlineLinkedin />
              </span>
              <span className="text-linkedin-header">
                {value === "fr"
                  ? "REJOIGNEZ NOUS SUR LINKEDIN"
                  : "JOIN US ON LINKEDIN"}
              </span>
            </p>
          </a>
          <p className="wrapper-list-lng">
            <span className="lng-selected">
              <span onClick={handleShowList} className="mt-1">
                {lngSelected}
              </span>
              <span
                className={`${showListLng ? "is-show-list" : "is-hide-list"}`}
              >
                <IoIosArrowForward />
              </span>
            </span>
            {showListLng && (
              <ul className="list-lng">
                <>
                  {lngSelected === "FRANÇAIS" ? (
                    <li onClick={() => handleChangeLng("ENGLISH")}>ENGLISH</li>
                  ) : (
                    <li onClick={() => handleChangeLng("FRANÇAIS")}>
                      FRANÇAIS
                    </li>
                  )}
                </>
              </ul>
            )}
          </p>
        </div>
      </div>
      <>{currentImage}</>

      <div className="bg-linear-header"></div>
      <div className="img-header-current-wrapper">
        {[...Array(sliderImgs.length).keys()].map((el, i) => (
          <span
            key={i}
            className={`${
              count === i
                ? "img-header-current-enable"
                : "img-header-current-disable"
            }`}
          ></span>
        ))}
      </div>
      <div className="wrapper-btn-img-header">
        <span className="btn-next-img-header" onClick={nextSlider}>
          <IoIosArrowForward />
        </span>
        <span className="btn-previous-img-header" onClick={prevSlider}>
          <IoIosArrowBack />
        </span>
      </div>

      <div className="content-header">
        <NavBar show={show} />
        <div className="container custom-container">
          {/* <div className="title-btn-header-wrapper">
            <p className="title-header">
              {value === "fr" ? (
                <>
                  <span>CONSTRUCTION</span>
                  <br />
                  <span>ENVIRONNEMENT</span>
                  <br />
                  <span>INGÉNIERIE</span>
                </>
              ) : (
                <>
                  <span>BUILT</span>
                  <br />
                  <span>ENVIRONMENT</span>
                  <br />
                  <span>ENGINEERING</span>
                </>
              )}
            </p>
            <p className="btn-header">
              <span>
                {value === "fr"
                  ? "découvrez nos activités ici"
                  : "discover our activities here"}
              </span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Header
