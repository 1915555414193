import React, { useEffect, useState } from "react"

const useActiveLinkNav = val => {
  const [activeMenu, setActiveMenu] = useState({
    home: true,
    about: false,
    advice: false,
    ecosystem: false,
    blog: false,
    contact: false,
  })

  useEffect(() => {
    switch (val) {
      case "home":
        setActiveMenu({
          home: true,
          about: false,
          advice: false,
          ecosystem: false,
          blog: false,
          contact: false,
        })
        break
      case "about":
        setActiveMenu({
          home: false,
          about: true,
          advice: false,
          ecosystem: false,
          blog: false,
          contact: false,
        })
        break

      case "advice":
        setActiveMenu({
          home: false,
          about: false,
          advice: true,
          ecosystem: false,
          blog: false,
          contact: false,
        })
        break

      case "ecosystem":
        setActiveMenu({
          home: false,
          about: false,
          advice: false,
          ecosystem: true,
          blog: false,
          contact: false,
        })
        break
      case "blog":
        setActiveMenu({
          home: false,
          about: false,
          advice: false,
          ecosystem: false,
          blog: true,
          contact: false,
        })
        break
      case "contact":
        setActiveMenu({
          home: false,
          about: false,
          advice: false,
          ecosystem: false,
          blog: false,
          contact: true,
        })
        break

      default:
        break
    }
  }, [val])

  return { activeMenu }
}

export default useActiveLinkNav
