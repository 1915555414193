import React, { useContext } from "react"

/* IMPORT PLUGINS */
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"

/* IMPORT COMPONENTS */
/* IMPORT STYLE */
import "./Footer.less"

/* IMPORT CONTEXT LNG */
/* IMPORT HELPERS */

/* IMPORT ICONS */
import { AiOutlineLinkedin } from "react-icons/ai"
import { lngContext } from "../lngContext"

const Footer = () => {
  const { value, setValue } = useContext(lngContext)

  return (
    <div className="wrapper-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="logo-footer">
              <StaticImage
                src="../../images/logo-navbar.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="logo buildeeng"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 wrapper-menu-footer">
            <div className="menu-footer">
              <ul className="list-menu-footer">
                <li onClick={() => scrollTo("#home")}>
                  {value === "fr" ? "Accueil" : "Home"}
                </li>
                <li onClick={() => scrollTo("#about")}>
                  {value === "fr" ? "A propos" : "About US"}
                </li>
                <li onClick={() => scrollTo("#advice")}>
                  {value === "fr" ? "Le conseil" : "Expertise"}
                </li>
                <li onClick={() => scrollTo("#ecosystem")}>
                  {value === "fr" ? "Equipe" : "Team"}
                </li>
                <li onClick={() => scrollTo("#blog")}>
                  {value === "fr" ? "Blog" : "Blog"}
                </li>
                <li onClick={() => scrollTo("#contact")}>
                  {value === "fr" ? "Contact" : "Contact"}
                </li>
              </ul>
            </div>
            <div className="linkedin">
              <a
                href="https://www.linkedin.com/company/buildeeng/"
                target="_blank"
              >
                <AiOutlineLinkedin /> Rejoignez nous sur linkedin
              </a>
            </div>
          </div>
        </div>
        <div className="copyright">Copiright &copy; Buildeeng 2022</div>
      </div>
    </div>
  )
}

export default Footer
