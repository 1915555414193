import React, { useContext } from "react"

/* IMPORT PLUGINS */

/* IMPORT COMPONENTS */
import CardPartner from "../CardPartner"

/* IMPORT STYLE */

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"
import PartnerData from "../../constants/PartnerData"

/* IMPORT HELPERS */
/* IMPORT ICONS */

const Partners = () => {
  const { value, setValue } = useContext(lngContext)

  const { state } = PartnerData()

  return (
    <>
      <p className="title-partner">
        {value === "fr"
          ? "NOS PARTENAIRES PRIVILÉGIÉS"
          : "OUR PRIVILEGED PARTNERS"}
      </p>
      <div className="row">
        <div className="col-md-6 custom-m-partner">
          <CardPartner
            logo={state.geximmPartner.logo}
            text={state.geximmPartner.text}
            link={state.geximmPartner.link}
          />
        </div>
        <div className="col-md-6 custom-m-partner">
          <CardPartner
            logo={state.vcmPartner.logo}
            text={state.vcmPartner.text}
            link={state.vcmPartner.link}
          />
        </div>
      </div>
      <div className="row second-line">
        <div className="col-md-6">
          <CardPartner
            logo={state.shinraiPartner.logo}
            text={state.shinraiPartner.text}
            link={state.shinraiPartner.link}
          />
        </div>
        <div className="col-md-6 custom-m-partner">
          <CardPartner
            logo={state.emiketicPartner.logo}
            text={state.emiketicPartner.text}
            link={state.emiketicPartner.link}
          />
        </div>
      </div>
    </>
  )
}
export default Partners
