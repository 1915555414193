import React, { useContext, useState } from "react"

/* IMPORT PLUGINS */
import axios from "axios"
import { Alert, Spinner } from "reactstrap"

/* IMPORT COMPONENTS */
/* IMPORT STYLE */
import "./Contact.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */
import { validators } from "../../lib/validators"

/* IMPORT ICONS */
import { RiMapPin2Fill } from "react-icons/ri"
import { FaPhoneAlt } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"

const Contact = () => {
  const { value, setValue } = useContext(lngContext)

  const [data, setData] = useState({
    fname: "",
    email: "",
    sujet: "",
    message: "",
  })

  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    sujet: "",
    message: "",
  })

  const handleChange = e => {
    const field = e.target.name
    const value = e.target.value

    const checker = validators[field]

    setData({ ...data, [field]: value })

    if (errors[field] && checker) {
      const validationMsg = checker(value)
      setErrors(prevErrors => ({ ...prevErrors, [field]: validationMsg }))
    }
  }

  const [msgSended, setMsgSended] = useState(false)
  const [loadigSend, setLoadigSend] = useState(false)

  const sendTimeOutMsg = () => {
    setMsgSended(true)
    setTimeout(() => {
      setMsgSended(false)
    }, 5000)
  }

  const myhandleSubmit = e => {
    setLoadigSend(true)
    e.preventDefault()
    const form = e.target
    const { fname, email, sujet, message } = data

    const errorsMsg = {
      fname: validators.fname(fname),
      email: validators.email(email),
      sujet: validators.sujet(sujet),
      message: validators.message(message),
    }

    const fieldsWithErrors = Object.keys(errorsMsg).filter(
      key => errorsMsg[key]
    )

    if (fieldsWithErrors.length) {
      setErrors(errorsMsg)
      setLoadigSend(false)
      return
    }

    if (fieldsWithErrors.length === 0) {
      axios({
        method: "post",
        url: "https://formspree.io/xoqyqyqq",
        data: new FormData(form),
      })
        .then(r => {
          console.log("success")
          setLoadigSend(false)
          sendTimeOutMsg()
        })
        .catch(r => {
          console.log("error", r)
          setLoadigSend(false)
        })
    }
  }

  return (
    <div className="wrapper-contact py-4" id="contact">
      <div className="container">
        <div className="title-contact">
          <p>Contact</p>
          <div className="bar-title-contact"></div>
        </div>
        <div className="content-contact mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="form-contact">
                <p className="title-form-contact">
                  {value === "fr" ? "Formulaire de contact" : "Contact form"}
                </p>
                <form onSubmit={myhandleSubmit}>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Nom et prénom *"
                    className={`${errors.fname && "error-input"} my-3`}
                    onChange={e => handleChange(e)}
                  />
                  <span className="message-error">{errors.fname}</span>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    className={`${errors.email && "error-input"} my-3`}
                    onChange={e => handleChange(e)}
                  />
                  <span className="message-error">{errors.email}</span>
                  <input
                    type="text"
                    id="sujet"
                    name="sujet"
                    placeholder="Sujet *"
                    className={`${errors.sujet && "error-input"} mt-3 mb-1`}
                    onChange={e => handleChange(e)}
                  />
                  <span className="message-error">{errors.sujet}</span>
                  <textarea
                    name="message"
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Message *"
                    className={`${
                      errors.message && "error-textarea"
                    } mt-5 mb-2`}
                    onChange={e => handleChange(e)}
                  ></textarea>
                  <span className="message-error">{errors.message}</span>
                  <input
                    className="input-submit"
                    type="submit"
                    value={value === "fr" ? "ENVOYER" : "SEND"}
                  />
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-information">
                <div className="content-contact-information">
                  <p className="title-content-contact-information">
                    {value === "fr"
                      ? "N'HÉSITEZ PAS À NOUS CONTACTER !"
                      : "DO NOT HESITATE TO CONTACT US !"}
                  </p>
                  <ul className="list-contact-information">
                    <li>
                      <RiMapPin2Fill />
                      72 RUE DUTOT 75015 PARIS
                    </li>
                    <li>
                      <FaPhoneAlt />
                      +33 9 52 04 09 95
                    </li>
                    <li>
                      <ImPrinter />
                      +33 8 97 10 85 78
                    </li>
                  </ul>
                </div>
                <div className="cadre-contact-information"></div>
              </div>
            </div>
          </div>
          <div className="row row-info-msg">
            <div className="col-md-12 col-sm-6">
              <div className="bloc-info-msg">
                {loadigSend && (
                  <div className="myspinner">
                    <Spinner color="info" />
                  </div>
                )}
                {msgSended && (
                  <Alert color="success" className="mt-2">
                    {value === "fr"
                      ? "VOTRE MESSAGE A ÉTÉ ENVOYÉ AVEC SUCCÈS"
                      : "YOU'RE MESSAGE HAS BEEN SENT SUCCESSFULLY"}
                  </Alert>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact
