export const validators = {
    fname: value => {
      if (value === "") {
        return "champ obligatoire"
      }
      return ""
    },
    email: value => {
      if (value === "") {
        return "champ obligatoire"
      }
      const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const checkEmail = regexEmail.test(String(value).toLowerCase())
      if (!checkEmail) {
        return "email invalid"
      }
      return ""
    },
    sujet: value => {
      if (value === "") {
        return "champ obligatoire"
      }
      return ""
    },
    message: value => {
      if (value === "") {
        return "champ obligatoire"
      }
      return ""
    },
  }