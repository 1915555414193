import React, { useState } from "react"

export const contentModalThreatsFr = () => {
  const [stateThreatsFr, setStateThreatsFr] = useState({
    text: (
      <>
        <p>
          Le contexte sécuritaire actuel, a augmenté notre niveau d’exposition
          aux menaces terroristes qu’on pourrait qualifier de classiques ou
          connues. Mais nous expose aussi à de nouveaux risques, tel que le «
          Tireur isolé » / « Active Shooter ».
        </p>

        <p>
          Le Tireur isolé met le responsable de la sécurité face à des défis de
          taille. Comment être préparé au risque qu’un ou plusieurs tireurs
          attaquent une entreprise avec des armes à feu?
        </p>

        <p>
          Le premier risque : les fumeurs à l’extérieur du bâtiment qui seraient
          une cible facile, et extrêmement exposée. Il n’est pas facile de
          prévenir ce risque, notamment quand aucune autre alternatives pour
          fumer « à l’intérieur des locaux » (jardin, terrasse, etc…).
          Néanmoins, l’entreprise se doit d’informer ses collaborateurs de
          l’existence de ce risque.
        </p>

        <p>
          Des fiches reflexes doivent être établies, testées et communiquées aux
          employés pour être paré à deux scénaris possibles : Tireur(s) dans le
          voisinage et Tireur(s) dans le bâtiment.
        </p>

        <p>
          Dans les quartiers d’affaires, un mécanisme d’alerte inter-entreprises
          aurait toute son importance, et permettrait de réduire/prévenir les
          risques. Cela passe par les relations qui doivent être établies avec
          les entreprises du voisinage.
        </p>

        <p>Dans le cas où l’attaque a lieu dans le voisinage, il faut :</p>
        <ul>
          <li>
            Etre en mesure de fermer toutes les issues permettant l’accès au
            bâtiment assez rapidement. Eventuellement prévoir un bouton
            d’urgence pour automatiser la fermeture.
          </li>
          <li>
            Etre en mesure d’informer tous les collaborateurs du danger, et les
            confiner dans un ou des endroits sûrs et loin des façades.
          </li>
          <li>
            Etre en contact avec les forces de l’ordre afin de pouvoir organiser
            l’évacuation de l’immeuble si cela s’avère être nécessaire.
          </li>
        </ul>
        <p>Dans le cas où l’attaque a lieu dans le bâtiment il faut :</p>
        <ul>
          <li>
            Informer les forces de l’ordre en précisant si possible le nombre de
            tireurs, type d’armes, etc..
          </li>
          <li>
            Etre en mesure de pouvoir informer les collaborateurs du danger,
            signaler l’endroit où se trouve le tireur
          </li>
          <li>
            La procédure suivante devra avoir été communiquée aux employés
          </li>
          <li>Evacuer/fuir:</li>
        </ul>
        <p>
          S’il existe un chemin accessible pour évacuer, il faut s’assurer de :
        </p>
        <ul>
          <li>Avoir le plan en tête pour pouvoir évacuer</li>
          <li>
            Evacuer indépendamment du fait que les autres acceptent de le faire
            ou non
          </li>
          <li>Laisser ses affaires</li>
          <li>Aider les autres à évacuer si c’est possible</li>
          <li>
            Empêcher d’autres personnes d’accéder à la zone où se trouve le
            tireur
          </li>
          <li>Garder ses mains en évidence</li>
          <li>Suivre les instructions des forces de l’ordre</li>
          <li>Ne pas déplacer les victimes</li>
          <li>
            Alertez lorsque vous êtes en sécurité (17 ou 112 ou la sécurité de
            votre bâtiment)
          </li>
          <li>Se cacher/se mettre à l’abri :</li>
        </ul>
        <p>
          S’il est impossible de fuir, trouver refuge à un endroit où le tireur
          ne pourrait pas vous trouver - Avoir le plan en tête pour pouvoir
          évacuer l’endroit en question
        </p>
        <ul>
          <li>
            Choisir un endroit hors de portée de vue du tireur, qui vous protège
            des coups de feu et qui dispose d’une issue
          </li>
          <li>
            Verrouiller/Bloquer la porte de votre cachette avec du mobilier ou
            tout autre obstacle qui empêcherait le tireur de vous atteindre,
            éteignez la lumière
          </li>
          <li>
            Eviter de faire du bruit, mettre son mobile sur silencieux (sans
            vibreur), se cacher derrière une armoire où quelque chose d’assez
            grand et garder son calme
          </li>
          <li>
            Prévenir la sécurité si possible pour indiquer où se trouve le
            tireur
          </li>
          <li>Aider les autres à évacuer si c’est possible</li>
        </ul>
        <p>Après l’incident :</p>
        <ul>
          <li>
            S’identifier auprès des forces de l’ordre, les mains bien en
            évidence et les aider à identifier les collaborateurs et à ne pas
            les confondre avec le tireur
          </li>
          <li>
            Prévenir le management et la sécurité s’ils ne sont pas prévenus
          </li>
          <li>
            Les locaux sont une scène de crime, ne toucher à rien avant
            l’arrivée de la police. Tous les témoins doivent rester à
            disposition des forces de l’ordre
          </li>
        </ul>
        <p>
          La communication envers les employés est un point fondamental de la
          gestion de ce type d’incidents.
        </p>

        <p>
          Il existe sur le marché des solutions de communication de masse,
          adaptées à ce genre de situation, pouvant supporter plusieurs canaux
          de communication simultanément : SMS, mail, message vocale. Et
          permettant d’envoyer instantanément – et simplement en se connectant à
          internet – un message à une population cible.
        </p>

        <p>
          Ces solutions sont aussi capables de gérer les réponses, et de faire
          office de « Safety check button ». En effet, en cas d’attaque
          terroriste, certains employeur soucieux de leur salariés vont chercher
          à s’assurer qu’ils sont saint et saufs (ce fut le cas lors des
          attaques du 13 novembre dernier). Les appeler un à un n’est pas
          forcément la meilleur des solutions, surtout pour des entreprises
          d’une certaine taille. Les solutions de communication de masse,
          permettent d’envoyer une communication et de demander/collecter les
          réponses et générer des statistiques. Dans l’urgence cela permet de
          réagir de manière assez efficace.
        </p>

        <p>
          Le contexte sécuritaire actuel, a augmenté notre niveau d’exposition
          aux menaces terroristes qu’on pourrait qualifier de classiques ou
          connues. Mais nous expose aussi à de nouveaux risques, tel que le «
          Tireur isolé » / « Active Shooter ».
        </p>

        <p>
          Le Tireur isolé met le responsable de la sécurité face à des défis de
          taille. Comment être préparé au risque qu’un ou plusieurs tireurs
          attaquent une entreprise avec des armes à feu?
        </p>

        <p>
          Le premier risque : les fumeurs à l’extérieur du bâtiment qui seraient
          une cible facile, et extrêmement exposée. Il n’est pas facile de
          prévenir ce risque, notamment quand aucune autre alternatives pour
          fumer « à l’intérieur des locaux » (jardin, terrasse, etc…).
          Néanmoins, l’entreprise se doit d’informer ses collaborateurs de
          l’existence de ce risque.
        </p>

        <p>
          Des fiches reflexes doivent être établies, testées et communiquées aux
          employés pour être paré à deux scénaris possibles : Tireur(s) dans le
          voisinage et Tireur(s) dans le bâtiment.
        </p>

        <p>
          Dans les quartiers d’affaires, un mécanisme d’alerte inter-entreprises
          aurait toute son importance, et permettrait de réduire/prévenir les
          risques. Cela passe par les relations qui doivent être établies avec
          les entreprises du voisinage.
        </p>
        <p>Dans le cas où l’attaque a lieu dans le voisinage, il faut :</p>
        <ul>
          <li>
            {" "}
            Etre en mesure de fermer toutes les issues permettant l’accès au
            bâtiment assez rapidement. Eventuellement prévoir un bouton
            d’urgence pour automatiser la fermeture.
          </li>
          <li>
            Etre en mesure d’informer tous les collaborateurs du danger, et les
            confiner dans un ou des endroits sûrs et loin des façades.
          </li>
          <li>
            Etre en contact avec les forces de l’ordre afin de pouvoir organiser
            l’évacuation de l’immeuble si cela s’avère être nécessaire
          </li>
        </ul>
        <p>Dans le cas où l’attaque a lieu dans le bâtiment il faut :</p>
        <ul>
          <li>
            Informer les forces de l’ordre en précisant si possible le nombre de
            tireurs, type d’armes, etc..
          </li>
          <li>
            Etre en mesure de pouvoir informer les collaborateurs du danger,
            signaler l’endroit où se trouve le tireur
          </li>
          <li>
            La procédure suivante devra avoir été communiquée aux employés
          </li>
          <li>Evacuer/fuir:</li>
        </ul>
        <p>
          S’il existe un chemin accessible pour évacuer, il faut s’assurer de :
        </p>
        <ul>
          <li>Avoir le plan en tête pour pouvoir évacuer</li>
          <li>
            Evacuer indépendamment du fait que les autres acceptent de le faire
            ou non
          </li>
          <li>Laisser ses affaires</li>
          <li>Aider les autres à évacuer si c’est possible</li>
          <li>
            Empêcher d’autres personnes d’accéder à la zone où se trouve le
            tireur
          </li>
          <li>Garder ses mains en évidence</li>
          <li>Suivre les instructions des forces de l’ordre</li>
          <li>Ne pas déplacer les victimes</li>
          <li>
            Alertez lorsque vous êtes en sécurité (17 ou 112 ou la sécurité de
            votre bâtiment)
          </li>
          <li>Se cacher/se mettre à l’abri :</li>
        </ul>
        <p>
          S’il est impossible de fuir, trouver refuge à un endroit où le tireur
          ne pourrait pas vous trouver - Avoir le plan en tête pour pouvoir
          évacuer l’endroit en question
        </p>
        <ul>
          <li>
            Choisir un endroit hors de portée de vue du tireur, qui vous protège
            des coups de feu et qui dispose d’une issue
          </li>
          <li>
            Verrouiller/Bloquer la porte de votre cachette avec du mobilier ou
            tout autre obstacle qui empêcherait le tireur de vous atteindre,
            éteignez la lumière
          </li>
          <li>
            Eviter de faire du bruit, mettre son mobile sur silencieux (sans
            vibreur), se cacher derrière une armoire où quelque chose d’assez
            grand et garder son calme
          </li>
          <li>
            Prévenir la sécurité si possible pour indiquer où se trouve le
            tireur
          </li>
          <li>Aider les autres à évacuer si c’est possible</li>
        </ul>
        <p>Après l’incident :</p>
        <ul>
          <li>
            S’identifier auprès des forces de l’ordre, les mains bien en
            évidence et les aider à identifier les collaborateurs et à ne pas
            les confondre avec le tireur
          </li>
          <li>
            Prévenir le management et la sécurité s’ils ne sont pas prévenus
          </li>
          <li>
            Les locaux sont une scène de crime, ne toucher à rien avant
            l’arrivée de la police. Tous les témoins doivent rester à
            disposition des forces de l’ordre
          </li>
        </ul>
        <p>
          La communication envers les employés est un point fondamental de la
          gestion de ce type d’incidents.
        </p>

        <p>
          Il existe sur le marché des solutions de communication de masse,
          adaptées à ce genre de situation, pouvant supporter plusieurs canaux
          de communication simultanément : SMS, mail, message vocale. Et
          permettant d’envoyer instantanément – et simplement en se connectant à
          internet – un message à une population cible.
        </p>

        <p>
          Ces solutions sont aussi capables de gérer les réponses, et de faire
          office de « Safety check button ». En effet, en cas d’attaque
          terroriste, certains employeur soucieux de leur salariés vont chercher
          à s’assurer qu’ils sont saint et saufs (ce fut le cas lors des
          attaques du 13 novembre dernier). Les appeler un à un n’est pas
          forcément la meilleur des solutions, surtout pour des entreprises
          d’une certaine taille. Les solutions de communication de masse,
          permettent d’envoyer une communication et de demander/collecter les
          réponses et générer des statistiques. Dans l’urgence cela permet de
          réagir de manière assez efficace.
        </p>
      </>
    ),
  })
  return { stateThreatsFr }
}
