import React, { useEffect, useRef, useState } from "react"

import AOS from "aos"
import "aos/dist/aos.css"

/* IMPORT COMPONENTS */
import Header from "../components/Header"
import Footer from "../components/Footer"
import About from "../components/About"
import Advice from "../components/Advice"
import EcoSystem from "../components/EcoSystem"
import Blog from "../components/Blog"
import Contact from "../components/Contact"

/* IMPORT STYLE */
import "bootstrap/dist/css/bootstrap.css"
import "./page.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../components/lngContext"

/* IMPORT HELPERS */
import useScroll from "../hooks/useScroll"
import SEO from "../components/seo"

const IndexPage = () => {
  // componentDidMount() {
  //   AOS.init({ once: true });
  // }

  // componentDidUpdate() {
  //   AOS.refresh();
  // }

  useEffect(() => {
    AOS.init({ once: true })
  })

  /* state lng provider */
  const [value, setValue] = useState("fr")

  /* START CONST REF */
  const isHeaderRef = useRef(null),
    isAboutRef = useRef(null),
    isAdviceRef = useRef(null),
    isEcosystemRef = useRef(null),
    isBlogRef = useRef(null),
    isContactRef = useRef(null)

  /* CUSTOM HOOK SCROLL */
  const { show } = useScroll(
    isHeaderRef,
    isAboutRef,
    isAdviceRef,
    isEcosystemRef,
    isBlogRef,
    isContactRef
  )

  return (
    <lngContext.Provider value={{ value, setValue }}>
      <SEO
        title="BUILT ENVIRONMENT ENGINEERING"
        description="Notre approche est globale, conjuguant Immobilier, Facilities Management et Workplace Management"
      />
      <div className="wrapper-header-page">
        <div ref={isHeaderRef}>
          <Header show={show} />
        </div>
        <div ref={isAboutRef}>
          <About />
        </div>
        <div ref={isAdviceRef}>
          <Advice />
        </div>
        <div ref={isEcosystemRef}>
          <EcoSystem />
        </div>
        <div ref={isBlogRef}>
          <Blog />
        </div>
        <div ref={isContactRef}>
          <Contact />
        </div>
        <Footer />
      </div>
    </lngContext.Provider>
  )
}

export default IndexPage
