import React, { useContext, useRef } from "react"

/* IMPORT PLUGINS */
import { StaticImage } from "gatsby-plugin-image"

/* IMPORT COMPONENTS */
import ModalExample from "../Modal"
import CardBlog from "../CardBlog"

/* IMPORT STYLE */
import "./Blog.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */
/* IMPORT ICONS */
import { IoIosArrowForward } from "react-icons/io"
import { IoIosArrowBack } from "react-icons/io"

import CardBlogData from "../../constants/CardBlogData"

const Blog = () => {
  const { cardBlogData } = CardBlogData()

  const { value, setValue } = useContext(lngContext)

  const isContentBlogRef = useRef(null)

  const scroll = scrollOffset => {
    isContentBlogRef.current.scrollLeft += scrollOffset
  }

  return (
    <div className="wrapper-blog py-4" id="blog">
      <div className="container">
        <div className="title-blog">
          <p>Blog</p>
          <div className="bar-title-blog"></div>
        </div>
        <div className="wrapper-scroll-blog">
          <span onClick={() => scroll(-250)} className="scroll-blog-icon-back">
            <IoIosArrowBack />
          </span>
          <div className="content-blog" ref={isContentBlogRef}>
            {cardBlogData &&
              cardBlogData.map((el, i) => (
                <div className="item-blog">
                  <CardBlog
                    img={el.img}
                    title={el.title}
                    titleModal={el.titleModal}
                  />
                </div>
              ))}
          </div>
          <span onClick={() => scroll(250)} className="scroll-blog-icon-next">
            <IoIosArrowForward />
          </span>
        </div>
      </div>
    </div>
  )
}

export default Blog
