import React, { useState } from "react"

export const contentModalExternalisation = () => {
  const [stateExternalisation, setStateExternalisation] = useState({
    text: (
      <>
        <p>
          Externalisation, Outsourcing, Facilities Management, des termes qui
          peuvent faire peur. Et pourtant, les exemples de réussite sont
          nombreux, certains contrats durent depuis plus de 10 ans. L'évolution
          des ces réussites a donné naissance à de nouveaux modèles de
          partenariat, tels que "Vested"* qui est considéré comme une évolution
          majeure de l’externalisation.
        </p>

        <p>
          Quels sont donc les facteurs de réussite pour aboutir à de tels
          évolutions?
        </p>

        <p>
          Un projet d'externalisation des "Services Généraux", est souvent
          initié par une volonté d'optimisation des coûts, de rationalisation,
          de réduction du "Headcount", ou encore des fois par une prise de
          conscience de l'importance de ce centre de coût et de son impact sur
          la performance de l'entreprise. Et donc de la nécessité de confier ça
          à un "sachant".
        </p>

        <p>
          Il existe différents modèles d'externalisation, Facilities Management
          Global, Integrated Facilities Management, etc... Chaque organisation
          doit trouver le modèle le mieux adapté à son activité et à sa taille.
        </p>

        <p>
          Le facteur humain est déterminant pour la réussite du projet, et ce à
          différents niveaux.
        </p>

        <p>
          Les ressources qui seront dédiées par le prestataire au service du
          client, arrivent souvent dans un environnement hostile, en fonction de
          la communication interne sur le projet faite par le client et de la
          résistance au changement des salariés qui assuraient ces fonctions.
          Lorsqu'il n'y a pas de reprise du personnel du client, il est
          important qu'il n'y ait pas de redondance, que le périmètre de chacun
          soit défini. Une réunion de kick-off à la prise d’effet du contrat,
          pour faire connaissance, et partager ensemble l'objectif du contrat et
          ses conditions est primordiale à mon sens pour partir sur de bonnes
          bases, et être certain de que tous les "stakeholder" partagent la même
          compréhension et la même interprétation du contrat.{" "}
        </p>

        <p>
          Elles arrivent souvent aussi sur des prestations qui ont pu être mal
          comprises, mal définies, mal calibrées et/ou mal chiffrées. La "Due
          Diligence"** revêt là toute son importance. Souvent sur des appels
          d'offres régionaux (j'entends par là une région du monde, exemple :
          EMEA), la "Due Diligence" est réalisée par des équipes régionales, ne
          connaissant pas forcément le marché local. Ou encore, certaines
          approches commerciales, qui négligent l'aspect conseil, privilégiant
          l'aspect financier.
        </p>

        <p>
          Les attentes du client sont toujours grandes : économies,
          professionnalisme, valeur ajoutée et qualité. Le prestataire doit
          mesurer l'importance de ces attentes et être à leur hauteur. Une
          mobilisation pour le démarrage du contrat avec des ressources
          supplémentaires est toujours appréciable, et permettra une
          stabilisation plus rapide de la prestation. Un bon démarrage ne peut
          qu'être bénéfique à tout le monde.{" "}
        </p>

        <p>
          {" "}
          Le choix du pilote FM côté prestataire est lui aussi un facteur
          déterminant pour la réussite du projet. En effet, aujourd'hui on
          s'accorde que tout est une question d'Hommes d'abord, et de
          prestataire ensuite. Le Pilote veille à ce que le service soit rendu
          et le client satisfait au quotidien, c'est lui qui impulse la
          dynamique du contrat et du service, notamment au sein de son équipe.
          Le prestataire doit être très attentif au choix de ce pilote, les
          Hommes (des deux côtés) et les organisations doivent être en harmonie.
          Aujourd'hui les clients sont unanimes : l'Homme est celui qui fait la
          différence entre un prestataire et un autre.
        </p>

        <p>
          Le pilote côté client lui, influera sur la vie du contrat par la
          dynamique qu'il insufflera et la relation qu'il mettra en place avec
          le prestataire. La gouvernance doit être clairement définie et
          traduire l'objectif défini par le client, et ne peut se résumer à un
          pourcentage d'économie annuelle. Un contrat d'externalisation doit se
          construire sur la durée. Il impacte l'environnement de travail des
          salariés et par conséquent, la performance de l'entreprise.
        </p>

        <p>
          La gestion de la relation ne peut être qu'une simple contradiction
          d'indicateurs et de reportings. Un échange et une critique
          constructive permettent d'explorer les solutions. L'affrontement, et
          la "violence contractuelle", ne peuvent que nuire au contrat et à la
          performance. Le contrat étant très souvent un contrat à obligation de
          résultats, le prestataire doit démontrer sa valeur ajoutée en
          innovant, en optimisant et en améliorant la prestation. Le client lui,
          doit être à l'écoute du prestataire pour atteindre le niveau de
          qualité souhaité, et le laisser apporter cette valeur ajoutée dans la
          façon de faire.
        </p>

        <p>
          La crainte de la perte de contrôle côté client, doit motiver une
          approche de partenariat qui est l'évolution naturelle du succès. le
          partage, un engagement bilatéral, un état d’esprit collaboratif sont
          les gages du succès.
        </p>
      </>
    ),
  })
  return { stateExternalisation }
}
