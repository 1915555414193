import React, { useContext } from "react"

/* IMPORT PLUGINS */

/* IMPORT COMPONENTS */
import CardAdvice from "../CardAdvice"

/* IMPORT STYLE */
import "./Advice.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"
import AdviceData from "../../constants/AdviceData"

/* IMPORT HELPERS */
/* IMPORT ICONS */

const Advice = () => {
  const { value, setValue } = useContext(lngContext)

  const { state } = AdviceData()

  return (
    <div className="wrapper-advice py-5" id="advice">
      <div className="container">
        <div className="title-advice">
          <p>{value === "fr" ? "Le conseil" : "Expertise"}</p>
          <div className="bar-title-advice"></div>
        </div>
        <p className="description-advice">
          {value === "fr"
            ? ` Une approche centrée sur l’expérience utilisateur dans son
          environnement de travail. Notre cabinet à taille humaine nous permet
          une certaine proximité de nos clients et beaucoup d’agilité. Nous
          sommes capables de nous adapter à une start-up qui se développe ou à
          une Multinationale bien établie.`
            : `We support our client to shape, build and run unique and sustainable Workplace for a safe, healthy, and inspiring work experience.
            We consider people as the company’s most valuable asset and greatest investment.
            Whatever it comes to Real Estate, Facilities Management, or Workplace Management, we bring in the right resource to provide the best in class expertise`}
        </p>
        <div className="expertises">
          {value === "fr" ? "NOS EXPERTISES" : "OUR EXPERTISE"}
        </div>
        <div className="wrapper-cards-advice">
          <div className="row">
            <div className="col-md-3 p-1">
              <CardAdvice
                bg="white"
                icon={state.objManagement.icon}
                title={state.objManagement.title}
                list={state.objManagement.list}
                color="#3D587F"
              />
            </div>
            <div className="col-md-3 p-1">
              <CardAdvice
                bg="blue"
                icon={state.objImmobilier.icon}
                title={state.objImmobilier.title}
                list={state.objImmobilier.list}
                color="#fff"
              />
            </div>
            <div className="col-md-3 p-1">
              <CardAdvice
                bg="white"
                icon={state.objGestion.icon}
                title={state.objGestion.title}
                list={state.objGestion.list}
                color="#3D587F"
              />
            </div>
            <div className="col-md-3 p-1">
              <CardAdvice
                bg="blue"
                icon={state.objCapital.icon}
                title={state.objCapital.title}
                list={state.objCapital.list}
                color="#fff"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Advice
