import React, { useState } from "react"

export const contentModalThreatsEn = () => {
  const [stateThreatsEn, setStateThreatsEn] = useState({
    text: (
      <>
        <p>
          Today’s world has increased our exposure to classic terrorist threats.
          Added to that, a new risk has emerged called the “Active shooter”.
        </p>

        <p>
          The “Active shooter” situation is a very challenging situation for the
          security manager. How to be ready and prepared to respond to an armed
          person using deadly physical force on other persons?
        </p>

        <p>
          The first risk: smoker in front of the building. They are heavily
          exposed and a very easy target. Preventing this kind of situation
          maybe difficult, especially when there’s no other possibility for
          smoking inside the building (garden, balcony, terrace, etc…). At
          minimum, the employer must inform the employees of the risk.
        </p>

        <p>
          “Active shooter” procedure must be implemented, tested and
          communicated to employees so that they are aware and ready for two
          possible scenarios: “Active shooter” in the vicinity and “Active
          shooter” inside the building.
        </p>

        <p>
          In business areas, putting in place an alerting system between
          companies may help to reduce/prevent risks. Networking is very
          important.
        </p>

        <p>
          For “Active shooter” in the vicinity situation, the employer must be
          able to:
        </p>

        <ul>
          <li>
            Lockdown the site and close all access ways inside the building;
            ideally with an Emergency button.
          </li>
          <li>
            Immediately advise all employees of active shooter situation, and
            provide instruction to confine away from the building facade
          </li>
          <li>
            Reach out to local police department to manage the evacuation of the
            building if necessary.
          </li>
        </ul>
        <p>
          For “Active shooter” inside the building situation, the employer must
          be able to:
        </p>
        <ul>
          <li>
            Provide the local police department with info about the number of
            shooters, type of guns, etc… if possible.
          </li>
          <li>
            Immediately advise all employees of active shooter situation, and
            provide information on the shooter’s position in the building.
          </li>
          <li>Assure that all employees are aware of the below procedure.</li>
        </ul>
        <p>1. Evacuate</p>
        <p>
          {" "}
          If there is an accessible escape path, attempt to evacuate the
          premises. Be sure to:
        </p>
        <ul>
          <li>Have an escape route and plan in mind</li>
          <li>Evacuate regardless of whether others agree to follow</li>
          <li>Leave your belongings behind</li>
          <li>Help others escape, if possible</li>
          <li>
            Prevent anyone from entering an area where the active shooter may be
          </li>
          <li>Keep your hands visible</li>
          <li>Follow the instructions of any police officers</li>
          <li>Do not attempt to move wounded people</li>
          <li>Call Security or police to alert when you are safe</li>
        </ul>
        <p>1. Hide out / Shelter in Place</p>
        <p>
          If evacuation is not possible, find a place to hide where the active
          shooter is less likely to find you.
        </p>
        <ul>
          <li>
            Shelter in a place out of the active shooter’s view, providing
            protection if fired upon, not trapping you or restricting your
            options for movement.
          </li>
          <li>
            Lock the door, or blockade it with heavy furniture, turn off the
            light.
          </li>
          <li>
            Make as little noise as possible, silence your cell phone (not
            vibrating), hide behind large items and remain quiet.
          </li>
          <li>
            Call Security or police to alert to the active shooter’s location if
            possible.
          </li>
          <li>Help others escape, if possible.</li>
        </ul>
        <p>1. After the incident:</p>

        <ul>
          <li>
            Safely engage with police and help them to not confuse employees
            with intruders.
          </li>
          <li>
            Alert management and security if they are not already advised.
          </li>
          <li>
            The entire facility is a crime scene, and should be secured and not
            disturbed until police arrive. All witnesses should remain at the
            police disposal.
          </li>
        </ul>

        <p>Communication is fundamental in managing this type of crisis.</p>

        <p>
          Mass notification systems are completely adapted for these situations,
          allowing to send alerts to a targeted audience simultaneously,
          anywhere around the world, via common voice and text-based
          communication points simply by connecting to internet.
        </p>

        <p>
          These systems can also verify alert delivery and status in real-time,
          recording and presenting voice and text responses as they are
          generated like a “safety check button”. In the event of a terrorist
          attack, some companies wants to check on their employee’s safety (like
          the Paris attack last November). Calling them one by one, is not the
          best solution especially for large organizations. Mass Notification
          systems allows to send an alert, collect replies and provide reports
          and statistics proving to be highly efficient in emergency situations.
        </p>

        <em>Translated from french with the help of Alyssa E. DERBAL</em>
      </>
    ),
  })
  return { stateThreatsEn }
}
