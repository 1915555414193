import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import isBrowser from "../../helpers/isBrowser"

import "./NavBar.less"

import { FaBars } from "react-icons/fa"
import useActiveLinkNav from "../../hooks/useActiveLinkNav"
import { lngContext } from "../lngContext"

const NavBar = ({ show }) => {
  const { value, setValue } = useContext(lngContext)

  const [showMenu, setShowMenu] = useState(false)

  const handleShowMenu = () => setShowMenu(true)
  const handleHideMenu = () => setShowMenu(false)

  const [scrolled, setScrolled] = useState(false)

  const checkIsBrowser = isBrowser()

  useEffect(() => {
    const onScroll = () => {
      const isTop = checkIsBrowser && window.scrollY > 40
      if (!isTop) {
        setScrolled(false)
      } else {
        setScrolled(true)
      }
    }

    checkIsBrowser && window.addEventListener("scroll", onScroll)

    return () => {
      checkIsBrowser && window.removeEventListener("onScroll", onScroll)
    }
  }, [scrolled])

  const [valNav, setValNav] = useState("")
  const getVal = val => {
    setValNav(val)
  }
  const { activeMenu } = useActiveLinkNav(valNav)

  return (
    <div
      className={`${scrolled ? "isScrolled" : "isNotScrolled"} wrapper-navbar`}
    >
      <div className="container">
        <div className="desktop-menu">
          <StaticImage
            src="../../images/logo-navbar.png"
            width={300}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="logo buildeeng"
          />
          <div className="list-menu-desktop">
            <span
              className="wrapper-link"
              onClick={() => {
                scrollTo("#home")
                getVal("home")
              }}
            >
              <Link className={`${show.isHeader && "border-menu"}`}>
                {value === "fr" ? "Accueil" : "Home"}
              </Link>
            </span>
            <span
              onClick={() => {
                scrollTo("#about")
                getVal("about")
              }}
            >
              <Link className={`${show.isAbout && "border-menu"}`}>
                {value === "fr" ? "A propos" : "About Us"}
              </Link>
            </span>
            <span
              onClick={() => {
                scrollTo("#advice")
                getVal("advice")
              }}
            >
              <Link className={`${show.isAdvice && "border-menu"}`}>
                {value === "fr" ? "Le conseil" : "Expertise"}
              </Link>
            </span>
            <span
              onClick={() => {
                scrollTo("#ecosystem")
                getVal("ecosystem")
              }}
            >
              <Link className={`${show.isEcosystem && "border-menu"}`}>
                {value === "fr" ? "Equipe" : "Team"}
              </Link>
            </span>
            <span
              onClick={() => {
                scrollTo("#blog")
                getVal("blog")
              }}
            >
              <Link className={`${show.isBlog && "border-menu"}`}>
                {value === "fr" ? "Blog" : "Blog"}
              </Link>
            </span>
            <span
              onClick={() => {
                scrollTo("#contact")
                getVal("contact")
              }}
            >
              <Link className={`${show.isContact && "border-menu"}`}>
                {value === "fr" ? "Contact" : "Contact"}
              </Link>
            </span>
          </div>
          <span className="btn-menu-mobile" onClick={handleShowMenu}>
            <FaBars />
          </span>
        </div>
      </div>

      {showMenu && (
        <div className="list-menu-mobile">
          <Link
            onClick={() => {
              scrollTo("#home")
              handleHideMenu
            }}
          >
            {value === "fr" ? "Accueil" : "Home"}
          </Link>
          <Link
            onClick={() => {
              scrollTo("#about")
              handleHideMenu
            }}
          >
            {value === "fr" ? "A propos" : "About"}
          </Link>
          <Link
            onClick={() => {
              scrollTo("#advice")
              handleHideMenu
            }}
          >
            {value === "fr" ? "Le conseil" : "Advice"}
          </Link>
          <Link
            onClick={() => {
              scrollTo("#ecosystem")
              handleHideMenu
            }}
          >
            {value === "fr" ? "ECOSystème" : "ECOSystem"}
          </Link>
          <Link
            onClick={() => {
              scrollTo("#blog")
              handleHideMenu
            }}
          >
            {value === "fr" ? "Blog" : "Blog"}
          </Link>
          <Link
            onClick={() => {
              handleHideMenu
              scrollTo("#contact")
            }}
          >
            {value === "fr" ? "Contact" : "Contact"}
          </Link>
          <span className="close-menu-mobile" onClick={handleHideMenu}>
            x
          </span>
        </div>
      )}
    </div>
  )
}

export default NavBar
