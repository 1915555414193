import React, { useContext } from "react"

/* IMPORT PLUGINS */
import { StaticImage } from "gatsby-plugin-image"

/* IMPORT COMPONENTS */

/* IMPORT STYLE */
import "./About.less"

/* IMPORT CONTEXT LNG */
import { lngContext } from "../lngContext"

/* IMPORT HELPERS */

/* IMPORT ICONS */

const About = () => {
  const { value, setValue } = useContext(lngContext)

  return (
    <div className="wrapper-about py-5" id="about">
      <div className="container">
        <div className="row custom-row">
          <div className="col-md-12 col-lg-6">
            <div className="bloc-description-about">
              <div className="title-about">
                <p>{value === "fr" ? "A PROPOS" : "ABOUT US"} </p>
                <span></span>
              </div>
              <div className="description-about">
                {value === "fr"
                  ? `Parce que l'environnement de travail se conçoit aujourd'hui
                comme une "Expérience" qui met au centre le collaborateur, son
                environnement et son bien-être au travail. Notre approche est
                globale, conjuguant Immobilier, Facilities Management et
                Workplace Management; car nous sommes convaincus que ces
                domaines ne peuvent se dissocier. Nous accompagnons les
                entreprises sur cette large palette de métiers. Nous apportons
                notre expertise à nos clients en mode projet, pour renforcer les
                équipes en place. En transition pour assurer le remplacement
                temporaire de ressources. Ou encore en mode conseil. Piloter et
                coordonner des projets d’aménagement, gérer des déménagements,
                consolider des portfolios, stabiliser des contrats FM où encore
                établir des plans stratégiques sont des missions que nous
                assurons pour nos clients Notre structure, et notre agilité nous
                permettent d’être au plus près de nos clients que nous
                considérons comme nos partenaires.`
                  : `We are a professional consulting company specialized in Built 
                  Environment : Real Estate, Facilities Management, Workplace 
                  Management, Project Management & Coordination and Human Capital. 
                  We support occupiers across EMEA region, in delivering the best Workplace for 
                  their 
                  employee fostering collaboration, innovation, growth and promoting wellbeing.
                  We provide a wide range of services : from Real Estate Strategy to building 
                  operations. We bring the best resource to support our clients.
                  We don’t only advise; we partner with our clients for success`}
              </div>
            </div>
          </div>
          <div className="col-md-10 col-sm-10 col-xs-10 col-lg-6">
            <div className="img-about">
              <StaticImage
                src="../../images/about.png"
                width={350}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="image about buildeeng"
              />
              <div className="cadre-img-about"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
